import React from 'react'

import Slider from '../../components/Slider/Slider'

import "./Home.css"

import { MdOutlineShutterSpeed } from "react-icons/md";
import { SiSecurityscorecard } from "react-icons/si";
import { IoArrowRedoSharp } from "react-icons/io5";

import Contact from '../contact/Contact';

const Home = () => {

  const cartesData = [
    {
      title: 'Rapidité',
      text: 'Nous nous déplaçons rapidement pour établir un devis. Nos équipes sont disponibles pour vous. Elles sont prêtes à intervenir dès que possible.',
      icon: <MdOutlineShutterSpeed className='font-icon' />
    },
    {
      title: 'Flexibilité',
      text: 'Nous cherchons à répondre au mieux à votre demande. Nous nous adaptons aux différentes contraintes : temps imparti, lieu géographique...',
      icon: <IoArrowRedoSharp className='font-icon' />

    },
    {
      title: 'Sécurité',
      text: 'Nous offrons à nos clients un service de qualité. Tous nos intervenants sont formés au respect des normes d\'hygiène et de sécurité.',
      icon: <SiSecurityscorecard className='font-icon' />
    },
  ];

  return (
    <>
      <Slider />
      <section className='home'>
        <div className='block-1'>
          <span>FLEXBETON</span>
          <h1>Entreprise de sciage et carottage à Paris et Île de France.</h1>
          <p>Vous voulez créer de nouveaux espaces ? Créer un escalier, un ascenseur, réunir des appartements ou élargir une porte d'entrée ?</p>
          <p>Nous sommes l'entreprise qu'il vous faut !</p>
        </div>

        <div className='block-2'>
          <div className='content-block-2'>
            <h2>
              Qui-sommes-nous ?
            </h2>
            <span className='line'></span>
            <p>
              Nous sommes une jeune entreprise intervenant sur tout type de chantier <span className='key'>entreprises, particuliers, immeubles de bureaux, locaux commerciaux, habitation...</span> Pour
              du sciage, carottage, forage ou démolition.
            </p>
            <p>
              Basés à Paris, nous intervenons dans toute l'île de France et éventuellement en province, selon les travaux à effectuer.
            </p>
            <p>
              Notre équipe est constituée <span className='key'>d'ingénieurs et d'artisans</span> avec plus de <span className='key'>10 années d'expérience</span> dans le sciage. Nous utilisons un <span className='key'>matériel de dernière technologie.</span>
              Vous pouvez compter sur notre <span className='key'>expertise</span>, notre <span className='key'>fiabilité</span> et notre <span className='key'>professionnalisme</span>.
            </p>
          </div>
          <div className='img-block-2'>
            <img src="/images/home/Scie.jpg" alt="beton" loading="lazy"/>
          </div>
        </div>

        <div className='block-3'>
          <h2>Pourquoi nous faire confiance?</h2>
          <span className='line'></span>
          <div className='content-block-3'>
            {cartesData.map((data, index) => (
              <div className='cards' key={index}>
                <div className='position-icon'>
                  <span>{data.title}</span>
                  <span>{data.icon}</span>
                </div>
                <p>{data.text}</p>
              </div>
            ))}
          </div>
        </div>

        <Contact/>
      </section>
    </>
  )
}

export default Home