import React from 'react'

import './Mentions.css'

const Mention = () => {
    return (
        <section className='mentions'>
            <h1>Mentions légales</h1>
            
            <h2>1. Propriétaire du site :</h2>
            <div className="paragraphe">
        <p>Nom : FLEXBETON</p>
        <p>Adresse : 29 RUE BOISSONADE 75014 PARIS </p>
        <p>
          Email : 
          <a href="flexbeton.france@gmail.com"> flexbeton.france@gmail.com</a>
        </p>
      </div>

            <h2>2. Créateur du site :</h2>
            <div className="paragraphe">
        <p>Nom : Delecroix Alexis</p>
        <p>Statut : Micro-entreprise</p>
        <p>Adresse : 21 GRAND rue 62144 Villers-au-Bois</p>
        <p>
          Email :
          <a href="mailto:d-alexis@hotmail.fr"> d-alexis@hotmail.fr</a>
        </p>
      </div>

            <h2>3. Hébergement :</h2>
            <div>
            <p>Le site FLEXBETON est hébergé par LWS, dont le siège social est situé 10 Rue de Penthièvre 75008 Paris.</p>
            </div>
        

            <h2>4. Propriété intellectuelle :</h2>

            <div>
            <p>Tous les contenus présents sur ce site (textes, images, vidéos, etc.) sont la propriété intellectuelle du créateur et du propriétaire du site, et sont protégés par les lois relatives aux droits d'auteur et à la propriété intellectuelle.</p>
            </div>
           

            <h2>5. Responsabilité :</h2>
            <div>
            <p>Les informations fournies sur ce site sont la responsabilité du propriétaire et du créateur du site. Ils s'efforcent de fournir des informations exactes et à jour, mais ne peuvent garantir l'exactitude, l'exhaustivité ou la pertinence des informations fournies.</p>
            </div>
          

            <h2>6. Contact :</h2>
            <div>
            <p>Pour toute question ou réclamation concernant ce site, vous pouvez contacter le propriétaire ou le créateur à l'adresse email indiquée ci-dessus.</p>
            </div>
          

            <p>Dernière mise à jour des mentions légales : 18/04/2024</p>
        </section>
    )
}

export default Mention