import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

import { FaPhoneAlt } from "react-icons/fa";

import './header.css';

const Header = () => {
  const [open, setOpen] = useState(false);

  const openMenu = () => {
    setOpen(!open);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  return (
    <header>
      <div className='logo'>
        <img src="/images/home/logo.png" alt="Logo du site" />
      </div>
      <div className={`${open ? 'nav' : 'nav mobile'}`}>
        <div className='first-nav'>
          <a href="tel:0602162004" className="button-tel"><FaPhoneAlt />06 02 16 20 04</a>
          <Link to="/contact" className="button-devis">Demande de devis</Link>
        </div>
        <nav className='nav-2'>
          <ul>
            <li><NavLink to="/" activeclassname="active" onClick={closeMenu}>Accueil</NavLink></li>
            <li><NavLink to="/sciage" activeclassname="active" onClick={closeMenu}>Sciage</NavLink></li>
            <li><NavLink to="/carottage" activeclassname="active" onClick={closeMenu}>Carottage</NavLink></li>
            <li><NavLink to="/demolition" activeclassname="active" onClick={closeMenu}>Démolition</NavLink></li>
            <li><NavLink to="/services" activeclassname="active" onClick={closeMenu}>Nos Réalisations</NavLink></li>
            <li><NavLink to="/contact" activeclassname="active" onClick={closeMenu}>Contact</NavLink></li>
          </ul>
        </nav>
        <div onClick={openMenu} className={`${open ? 'nav_burger actif' : 'nav_burger'}`}>
          <span className='burger_bar1'></span>
          <span className='burger_bar2'></span>
          <span className='burger_bar3'></span>
        </div>
      </div>
    </header>
  );
};

export default Header;