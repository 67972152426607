import React from 'react'

import { Link } from 'react-router-dom';

import './Footer.css'


import { IoIosMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { GiPositionMarker } from 'react-icons/gi'
import { AiFillCopyrightCircle } from 'react-icons/ai'

const Footer = () => {
  return (
    <footer>

      <div className='footer2'>
        <h3>Notre localisation</h3>
        <p><GiPositionMarker className='icon' />29 Rue Boissonade 75014 Paris 14</p>
      </div>

      <div className='footer1'>
        <h3>Nos coordonnées</h3>
        <a href="mailto:flexbeton.france@gmail.com"><IoIosMail className='icon' />flexbeton.france@gmail.com</a>
        <a href="tel:+0602162004"><FaPhoneAlt className='icon' />06 02 16 20 04</a>
      </div>


      <div className='footer3'>
        <h3>Informations</h3>
        <Link to='/Mentions_legales'>Mentions légales</Link>
      </div>

      <div className='footer4'>
        <p>Copyright Alexis Delecroix <AiFillCopyrightCircle className='icon' /> 2024</p>
      </div>
    </footer>
  )
}

export default Footer