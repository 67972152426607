import React from 'react'

import './style/carottage.css'

import { VscDebugBreakpointLog } from "react-icons/vsc";
import { IoIosWarning } from "react-icons/io";

const Carottage = () => {
  return (
    <>
      <section className='carottage'>
        <div className='title'>
          <h1>Carottage</h1>
          <span className='line'></span>
        </div>
        <div className='content-carottage'>
          <p>Le carottage est une opération consistant à découper dans un terrain un échantillon cylindrique
            appelé carotte. Le carottage consite donc à réaliser une <span className='key'>ouverture</span> en vue d'un passage de gaines :
          </p>

          <div className='list-cards'>
            <ul>
              <li><span><VscDebugBreakpointLog className='icon' /></span>Tuyaux de plomberie</li>
              <li><span><VscDebugBreakpointLog className='icon' /></span>Fourreaux électriques</li>
              <li><span><VscDebugBreakpointLog className='icon' /></span>Gaines de ventilation, d'aération</li>
              <li><span><VscDebugBreakpointLog className='icon' /></span>Canalisations</li>
              <li><span><VscDebugBreakpointLog className='icon' /></span>Câbles</li>
            </ul>
          </div>

          <p>Ces ouvertures peuvent être créées dans des <span className='key'>murs porteurs ou dalles béton</span>. </p> <p>Nous sommes aussi <span>habilités</span>  à créer des forages (puits verticaux) de diverses profondeurs.</p>

        </div>

        <div className='img-carottage'>
          <img src="/images/services/carottage.jpg" alt="Carottage / Forage" />
        </div>
      </section>

      <section className='carottage-2'>
        <div className='content-carottage-2'>
          <p>
            Nous intervenons sur tout type de bâtiments :
          </p>

          <div className='list-cards'>
            <ul>
              <li><span><VscDebugBreakpointLog className='icon' /></span>Bureaux</li>
              <li><span><VscDebugBreakpointLog className='icon' /></span>Appartements</li>
              <li><span><VscDebugBreakpointLog className='icon' /></span>Commerces</li>
              <li><span><VscDebugBreakpointLog className='icon' /></span>Terrasses</li>
              <li><span><VscDebugBreakpointLog className='icon' /></span>Intérieur ou extérieur</li>
            </ul>
          </div>

       <p><span className='key'>Rapidité</span> d'intervention et <span className='key'>qualité</span> garantie !</p>
        </div>

        <div className='warning'>
          <span className='position-icon'><IoIosWarning className='icon-warning' /></span>
          <p>Avant chaque chantier, en cas d'ouverture dans un mur porteur, l'expertise d'un bureau d'étude structure doit être
            sollicitée par vos soins.
          </p>
          <p> À la fin de chaque chantier, nous assurons l'enlèvement des gravats et le nettoyage. <br /> Électricité, peinture, remise en état à votre charge.</p>
        </div>
      </section>

      <section className='carottage-3'>
      <div className='content-carottage-3'>
          <div>
            <h2>Matériel</h2>
            <span className='line2'></span>
          </div>

          <p>Vous pouvez compter sur la <span className='key'>haute technologie</span> de notre matériel : scies de différents diamètres,
            pompes à eau garantissant une découpe sans poussière.
          </p>

          <div className='block-img'>
          <img src="/images/services/Carottage2.jpg" alt="Machine carottage" loading="lazy"/>
          <img src="/images/services/carottage.jpg" alt="Machine carottage" loading="lazy"/>
          <img src="/images/services/carottage1.jpg" alt="Machine carottage" loading="lazy"/>
          <img src="/images/services/Forage2.jpg" alt="Machine carottage" loading="lazy"/>
          </div>
        </div>
      </section>
    </>
  )
}

export default Carottage