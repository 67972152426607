import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

import './Contact.css'

const Contact = () => {
  const [randomWord, setRandomWord] = useState(generateRandomWord(6));
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    userInput: ''
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const userID = process.env.REACT_APP_EMAILJS_USER_ID;

  // Fonction pour générer un mot aléatoire
  function generateRandomWord(length) {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let randomWord = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomWord += characters.charAt(randomIndex);
    }
    return randomWord;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.userInput === randomWord) {
      // Soumission acceptée, envoi à EmailJS
      emailjs.sendForm(serviceID, templateID, e.target, userID)
        .then((result) => {
          console.log('E-mail envoyé avec succès :', result.text);
          // Réinitialiser le formulaire après l'envoi
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: '',
            userInput: ''
          });
          // Générer un nouveau mot aléatoire
          setRandomWord(generateRandomWord(6));
          // Afficher le message de succès
          setSuccessMessage('Votre message a été envoyé avec succès !');
          // Effacer les messages d'erreur précédents
          setError('');
        })
        .catch((error) => {
          console.error('Erreur lors de l\'envoi de l\'e-mail :', error);
          // Afficher un message d'erreur en cas d'échec de l'envoi
          setError('Une erreur s\'est produite lors de l\'envoi de votre message. Veuillez réessayer.');
          // Effacer le message de succès précédent
          setSuccessMessage('');
        });
    } else {
      // Soumission rejetée
      setError('Le mot saisi ne correspond pas au mot affiché');
      // Effacer le message de succès précédent
      setSuccessMessage('');
    }
  };

  return (
    <section className='contact'>
         <div className='title'>
          <span>
            Nous contacter ?
          </span>
          <span className='line'></span>
        </div>
      <div className='content-contact'>
       

        <div className='position-form'>
        <p>
          Pour toute demande de contact, veuillez remplir le formulaire ci-dessous. Nous vous répondrons dans les plus brefs délais.
        </p>

        {error && <div className="error-message">{error}</div>}
        {successMessage && <div className="success-message">{successMessage}</div>}

        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-row">
            <input type="text" id="firstName" name="firstName" placeholder="Prénom" value={formData.firstName} onChange={handleChange} required />
            <input type="text" id="lastName" name="lastName" placeholder="Nom" value={formData.lastName} onChange={handleChange} required />
          </div>
          <div className="form-row">
            <input type="email" id="email" name="email" placeholder="E-mail" value={formData.email} onChange={handleChange} required />
            <input type="tel" id="phone" name="phone" placeholder="Numéro de téléphone" value={formData.phone} onChange={handleChange} required />
          </div>
          <div className="form-message">
            <textarea id="message" name="message" placeholder="Bonjour, je me permets de vous contacter..." value={formData.message} onChange={handleChange} rows={4} required />
          </div>
          <div className="form-col">
            <span className="random-word">Recopiez le mot suivant : <strong>{randomWord}</strong></span>
            <input type="text" id="userInput" name="userInput" value={formData.userInput} onChange={handleChange} required />
          </div>
          <button type="submit">Envoyer</button>
        </form>
        </div>
      </div>

      <div className='maps'>
      <img src="/images/home/maps.png" alt="" />
      </div>
    </section>
  );
};

export default Contact;
