import React, { useState, useEffect } from 'react';
import ServicesData from './ServicesData.json';


import './Services.css'



const Services = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setProjects(ServicesData.slice(0, 4));
    setIsLoading(false);
  }, []);

  const loadMoreProjects = () => {
    const startIndex = page * 4;
    const endIndex = startIndex + 4;
    const newProjects = ServicesData.slice(startIndex, endIndex);
    setProjects([...projects, ...newProjects]);
    setPage(page + 1);
  };

  return (
    <section className="Services">
      <div className='title'>
        <h1>
          Nos Réalisations
        </h1>
        <span className='line'></span>
      </div>
   
        <div className='text'>
        <p>Découvrez quelques-unes de nos réalisations précédentes, telles que l'ouverture de murs porteurs, la création de gaines d'ascenseur, le sciage de dalles
           en béton armé et bien d'autres projets variés...</p>
        </div>

    <div>
      <div className="style-projects">
        {isLoading ? (
          <p>Chargement en cours...</p>
        ) : (
          projects.map((project) => (
            <div key={project.id} className="project">
              <div className='data-project'>
              <h3>{project.title}</h3>
              <p>{project.description}</p>
              </div>
             
              <img src={project.imageUrl} alt={project.title} />
            </div>
          ))
        )}
      </div>
    </div>
    <div className='btn'>
    {!isLoading && (
        <button onClick={loadMoreProjects}>Voir plus</button>
      )}
      </div>
    </section>
  );
};

export default Services;
