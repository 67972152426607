import React from 'react'

import './style/sciage.css'

import { VscDebugBreakpointLog } from "react-icons/vsc";
import { IoIosWarning } from "react-icons/io";

const Sciage = () => {
  return (
    <section className='sciage'>
      <div className='title'>
      <h1>Sciage</h1>
      <span className='line'></span>
      </div>
      <div className='content-sciage'>
        <p>Que vous soyez un particulier ou une entreprise, nous vous proposons des prestations de sciage. Nos services sont très variés :</p>

        <div className='list'>
          <div className='list-cards'>
            <ul>
              <li><span><VscDebugBreakpointLog className='icon'/></span>Ouverture de murs porteurs</li>
              <li><span><VscDebugBreakpointLog className='icon'/></span>murs mitoyens</li>
              <li><span><VscDebugBreakpointLog className='icon'/></span>murs de jardin</li>
              <li><span><VscDebugBreakpointLog className='icon'/></span>élargissement de portail ou porte d'entrée</li>
            </ul>
          </div>
          <div className='list-cards'>
            <ul>
              <li> <span><VscDebugBreakpointLog className='icon'/></span>Sciage au sol entre deux étages (escalier, ascenseur)</li>
              <li> <span><VscDebugBreakpointLog className='icon'/></span>intérieur ou extérieur</li>
              <li> <span><VscDebugBreakpointLog className='icon'/></span>ouverture de tranchées</li>
              <li> <span><VscDebugBreakpointLog className='icon'/></span>dalles, trottoirs</li>
            </ul>
          </div>
        </div>
        <p>
          Nous pouvons créer des ouvertures dans tout type de matériaux : béton, brique, parpaing, pierre... et tout type de bâtiments : appartements, bureaux, commerces, jardins, terrasses...
        </p>

       
      </div>


      <div className='img-sciage'>
        <img src="/images/services/sciage-beton.png" alt="sciage dalle béton" loading="lazy"/>
      </div>

      <div className='warning'>
          <span className='position-icon'><IoIosWarning className='icon-warning' /></span>
          <p>Avant chaque chantier, en cas d'ouverture dans un mur porteur, l'expertise d'un bureau d'étude structure doit être
            sollicitée par vos soins.
          </p>
          <p> À la fin de chaque chantier, nous assurons l'enlèvement des gravats et le nettoyage. <br /> Électricité, peinture, remise en état à votre charge.</p>
        </div>

      <div className='sciage-block2'>
        <div>
          <h2>Matériel</h2>
          <span className='line2'></span>
          </div>
        
        <p>Vous pouvez compter sur la <span className='key'>haute technologie</span> de notre matériel : scies tournantes,
        scies hydrauliques, de différents diamètres, sciage à la main..</p>
        <div className='block2-img'>
          <img src="/images/services/scies-tournantes.jpg" alt="Scies tournantes" loading="lazy"/>
          <img src="/images/services/sciage3.jpg" alt=" Scies"  loading="lazy"/>
          <img src="/images/services/sciage4.png" alt=" Scies"  loading="lazy"/>
          <img src="/images/services/scie5.png" alt=" Scies"  loading="lazy"/>
        </div>
        <p>Notre savoir-faire vous garantit un <span className='key'>résultat impeccable </span>  !</p>
      </div>
    </section>
  )
}

export default Sciage