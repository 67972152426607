import React from 'react'

import './style/demolition.css'

import { VscDebugBreakpointLog } from "react-icons/vsc";

const Demolition = () => {
  return (
    <section className='demolition'>
      <div className='title'>
        <h1>Démolition</h1>
        <span className='line'></span>
      </div>

      <div className='content-demolition'>
        <p>Explorez nos services complets de démolition, conçus pour répondre à tous
          vos besoins. Notre équipe expérimentée
          est prête à vous fournir une solution efficace et professionnelle.</p>

        <div className='list-cards'>
          <ul>
            <li><span><VscDebugBreakpointLog className='icon' /></span>Murs</li>
            <li><span><VscDebugBreakpointLog className='icon' /></span>planchers</li>
            <li><span><VscDebugBreakpointLog className='icon' /></span>Dalles</li>
            <li><span><VscDebugBreakpointLog className='icon' /></span>Démolition partielle ou totale</li>
          </ul>
        </div>
      </div>

      <div className='img-demolition'>
        <img src="/images/home/demolition.jpg" alt="Demolition" />
      </div>

      <div className='content-demolition-2'>
        <div className='title'>
        <h2>Sécurité</h2>
        <span className='line'></span>
        </div>
        <p>La sécurité est notre priorité absolue dans tous les aspects de notre travail. Nous mettons
          en place des mesures rigoureuses pour assurer la sécurité de nos équipes, de nos clients
          et des sites où nous intervenons.
        </p>

        <p>Nous commençons par établir un environnement sécurisé en installant des étais et des
          échafaudages lorsque nécessaire, ainsi qu'en mettant en place des dispositifs de
          protection sur le site. Nous insistons sur l'utilisation d'Équipements de Protection
          Individuelle (EPI) appropriés pour tous nos travailleurs, garantissant ainsi leur
          sécurité et leur bien-être.</p>

        <p>Nous accordons une grande importance à l'entretien régulier de nos machines et outils.
          Notre équipe effectue des vérifications périodiques pour s'assurer que tout fonctionne
          correctement et en toute sécurité.</p>

        <p>De plus, nous suivons strictement les réglementations en matière de sécurité sur les
          chantiers de construction et nous nous engageons à respecter toutes les normes et
          directives applicables. Notre objectif est de créer un environnement de travail
          sécurisé où chacun peut exercer ses fonctions en toute confiance et sans risque
          pour sa santé ou sa sécurité.</p>
      </div>

      <div className='content-demolition-3'>
      <div className='title'>
        <h2>Matériel</h2>
        <span className='line'></span>
        </div>

        <p>Nos équipes travaillent avec un matériel performant :</p>

        <div className='block-img'>
          <img src="/images/services/benne.jpg" alt="Benne" loading="lazy"/>
          <img src="/images/services/materiel-2.jpg" alt="monte charge"  loading="lazy"/>
          <img src="/images/services/materiel-3.jpg" alt=" Mini-pelle"  loading="lazy"/>
        </div>
      </div>
    </section>
  )
}

export default Demolition