import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import Sciage from './pages/prestations/Sciage';
import Demolition from './pages/prestations/Demolition';
import Carottage from './pages/prestations/Carottage';
import Services from './pages/realisations/Services';
import Contact from './pages/contact/Contact';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Mentions from './pages/mentionslegal/Mention';

import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop/>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sciage" element={<Sciage />} />
          <Route path="/demolition" element={<Demolition />} />
          <Route path="/carottage" element={<Carottage />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Mentions_legales" element={<Mentions />} />

        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
