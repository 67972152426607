import React, { useState, useEffect } from 'react';
import './Slider.css';

import { FaArrowLeft } from 'react-icons/fa6';
import { FaArrowRight } from 'react-icons/fa6';

import { Link } from 'react-router-dom';

const Slider = () => {
    const slidesData = [
        {
            title: 'Sciage',
            text: 'Sciage mural, ouverture murs porteurs, ouverture dalle béton...',
            buttonText: 'En savoir plus',
            image: '/images/home/scie5.jpg',
            url: 'sciage'
        },
        {
            title: 'Carottage',
            text: 'Création de conduits pour aération, ventilation passages de gaines...',
            buttonText: 'En savoir plus',
            image: '/images/home/carottage.jpg',
            url: 'carottage'
        },
        {
            title: 'Démolition',
            text: 'Démolition tout type de murs, cloison, intérieur extérieur...',
            buttonText: 'En savoir plus',
            image: '/images/home/demolition.jpg',
            url: 'demolition'
        },
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide(prevSlide => (prevSlide === slidesData.length - 1 ? 0 : prevSlide + 1));
    };

    const prevSlide = () => {
        setCurrentSlide(prevSlide => (prevSlide === 0 ? slidesData.length - 1 : prevSlide - 1));
    };

    useEffect(() => {
        const interval = setInterval(nextSlide, 5000); // Changez cette valeur pour ajuster la vitesse du carrousel
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="carousel-container">
            {slidesData.map((slide, index) => (
                <div key={index} className={`slide ${index === currentSlide ? 'active' : ''}`}>
                    <img height='600px' src={slide.image} alt={slide.title} loading="lazy" />
                    <div className="slide-content">
                        <div className='content'>
                            <h2>{slide.title}</h2>
                            <p>{slide.text}</p>
                            <Link to={slide.url}><button aria-label={`En savoir plus sur ${slide.title}`} className="slider-button">{slide.buttonText}</button></Link>
                        </div>
                    </div>
                </div>
            ))}
            <div className="controls">
                <button onClick={prevSlide} aria-label="Diapositive précédente"><FaArrowLeft /></button>
                <button onClick={nextSlide} aria-label="Diapositive suivante"><FaArrowRight /></button>
            </div>
            <div className="indicators">
                {slidesData.map((slide, index) => (
                    <span
                        key={index}
                        className={index === currentSlide ? 'indicator active' : 'indicator'}
                        onClick={() => setCurrentSlide(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default Slider;
